import React from 'react';
import { Card } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const EndContact = ({ emailAlias }) => (
  <Card fluid>
    <Card.Content>
      <b>
        If you still need any support for this service, please contact us via email:
        {' '}
        <a href={`mailto:${emailAlias}@kassellabs.io`}>
          {emailAlias}
          @kassellabs.io
        </a>
      </b>
    </Card.Content>
  </Card>
);

EndContact.propTypes = {
  emailAlias: PropTypes.string,
};

export default EndContact;
