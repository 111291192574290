import React from 'react';
import PropTypes from 'prop-types';

const TermsInitial = ({ name, website }) => (
  <>
    <p>
      These Terms of Service (“Terms”, “Terms of Service”) refers to the
      {' '}
      <a href={website}>
        {name}
        {' '}
      website
      </a>
      {' '}
      (the “Service”) operated by Kassel Labs (“us”, “we”, or “our”).
      Your access to and use of the Service is conditioned
      on your acceptance of and compliance with these Terms.
      These Terms apply to all visitors, users and others who access or use the Service.
    </p>
    <p>
      By accessing the Service, you are agreeing to be bound by these terms of service.
      If you do not agree with any of these terms, you are prohibited from using or
      accessing this Service.
    </p>
  </>
);

TermsInitial.propTypes = {
  name: PropTypes.string,
  website: PropTypes.string,
};

export default TermsInitial;
