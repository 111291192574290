import React from 'react';

import ProductPageLayout from '../components/ProductPageLayout';
import faq from '../faq/westworld';
import Terms from '../termsOfService/westworld.mdx';

const SecondPage = () => (
  <ProductPageLayout
    title="Westworld Intro Creator"
    emailAlias="Westworld"
    website="https://westworldintrocreator.kassellabs.io"
    faq={faq}
    terms={<Terms />}
  />
);

export default SecondPage;
