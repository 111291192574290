import React from 'react';
import { Accordion, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import faqGeneral from '../faq/general';
import TermsGeneral from '../termsOfService/general.mdx';
import TermsEnd from '../termsOfService/end.mdx';

import Layout from './layout';
import SEO from './seo';
import TermsInitial from './TermsInitial';
import EndContact from './EndContact';
import BackButton from './BackButton';

const ProductPageLayout = ({
  title,
  website,
  faq,
  terms,
  emailAlias,
  mergeFaq = true,
  mergeTerms = true,
}) => {
  const faqData = mergeFaq ? [...faqGeneral, ...faq] : faq;
  const mergedFaq = faqData.map((item, index) => ({ ...item, key: index }));

  return (
    <Layout>
      <SEO title={title} />

      <BackButton />
      <h1>{title}</h1>

      <Button as="a" href="#faq" basic color="black">
        FAQ
      </Button>
      <Button as="a" href="#termsOfService" basic color="black">
        Terms Of Service
      </Button>
      <Button as="a" href="#contact" basic color="black">
        Contact
      </Button>
      <Button as="a" href={website} basic color="black">
        {title}
      </Button>

      <h1 id="faq">FAQ (Frequently Asked Questions)</h1>
      <p>Click on the questions below to see the answer.</p>
      <br />
      <Accordion className="faq-accordion" styled panels={mergedFaq} exclusive={false} fluid />
      <br />
      <h1 id="termsOfService">Terms of Service</h1>
      <TermsInitial
        name={title}
        website={website}
      />
      {mergeTerms && <>
        <TermsGeneral />
        <br />
      </>}
      {terms}
      <br />
      <TermsEnd />
      <h1 id="contact">Contact</h1>
      <EndContact emailAlias={emailAlias} />
      <br />
      <BackButton />
    </Layout>
  );
};

ProductPageLayout.propTypes = {
  title: PropTypes.string,
  website: PropTypes.string,
  emailAlias: PropTypes.string,
  faq: PropTypes.array,
  terms: PropTypes.node,
  mergeFaq: PropTypes.bool,
  mergeTerms: PropTypes.bool,
};

export default ProductPageLayout;
